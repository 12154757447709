<template>
    <div v-if="component_done_loading">
        <test-topbar :test-obj="test" :options="{ forceHideTimer: true }"></test-topbar>
        <main class="col-xs-12 right-container">
            <div class="instruction_panel marginBottom20">
                <div class="x_header">
                    <h2>Prework Instructions</h2>
                </div>
                <div class="x_content">
                    <div class="col-xs-12">
                        <label class="control-label">Activity Type</label>
                        <p class="form-control-static">
                            <template v-if="test.type == 'prework'"> Prework </template>
                            <template v-if="test.type == 'irat'"> IRAT </template>
                            <template v-if="test.type == 'trat'"> TRAT </template>
                            <template v-if="test.type == 'iratntrat'"> IRAT & TRAT </template>
                            <template v-if="test.type == 'application'"> Application Case </template>
                            <template v-if="test.type == 'evaluation'"> 360 Degree Evaluation </template>
                            <template v-if="test.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                        </p>
                    </div>
                    <div v-if="test.settings.type == 'asynchronous'" class="col-xs-12">
                        <label class="control-label">Open Period</label>
                        <p class="form-control-static">
                            {{ convertToReadableDate(test.settings.startDate, 'DD MMM YYYY, h:mm a').date }}
                            ({{ convertToReadableDate(test.settings.startDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                        </p>
                    </div>
                    <div v-if="test.settings.type == 'asynchronous'" class="col-xs-12">
                        <label class="control-label">End Period</label>
                        <p class="form-control-static">
                            {{ convertToReadableDate(test.settings.endDate, 'DD MMM YYYY, h:mm a').date }}
                            ({{ convertToReadableDate(test.settings.endDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                        </p>
                    </div>
                    <div class="col-xs-12">
                        <label class="control-label">Activity Instructions</label>
                        <template v-if="test.settings.instruction">
                            <div v-dompurify-html="test.settings.instruction"></div>
                        </template>
                        <template v-else>
                            <p class="form-control-static marginBottom0">-</p>
                        </template>
                    </div>
                    <div v-if="test.settings.attachments.length > 0" class="col-xs-12 col-md-6 marginTop20">
                        <div class="flex align-items">
                            <div class="col-xs-12">
                                <template v-for="(attachment, idx) in test.settings.attachments">
                                    <div class="flexSpaceBetween align-items attachmentFile">
                                        <div class="marginRight10">{{ idx + 1 }}.</div>
                                        <div class="col-xs-10 wordBreakAll paddingRight10">
                                            <span>
                                                <i class="far fa-file-pdf" aria-hidden="true"></i>
                                            </span>
                                            <a :href="attachment.url" target="_blank" class="attachmentLink">{{ attachment.filename }}</a>
                                        </div>
                                        <div>
                                            <a :href="attachment.url" target="_blank">
                                                <button class="btn btn-primary" :aria-label="'View Attachment ' + idx + 1">View</button>
                                            </a>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <kr-search
                name="materials_listing"
                :url="{
                    url: !test._isTeacherPreview ? 'student/tests/' + $route.params.id + '/materials' : 'activities/' + $route.params.id + '/materials',
                }"
                :columns="columns"
                :options="{
                    suspended_field: false,
                    trashed_field: false,
                    search_field: false,
                    table_caption: true,
                    top_bar: false,
                }"
                @retrieve-data="getMaterialsData"
            >
                <template v-if="test._isTeacherPreview" #top-display-from-to="props">
                    <p class="textAlignRight">{{ props.model.to }} of {{ props.model.meta.paginator.total }} Materials</p>
                </template>
                <template #table-caption="props">
                    All Materials ({{ props.model.to }}
                    of
                    {{ props.model.meta.paginator.total }})
                </template>
                <template #bottom-display-from-to="props">
                    <p class="textAlignRight">
                        {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </p>
                </template>
                <template #checkbox>
                    <template v-if="test.settings.allowPdfDownload">
                        <div class="newCheckboxField justifyCenter align-items flex smaller-ver">
                            <input id="preworkStudentSelectAll" type="checkbox" :checked="isAllToggled" @click="toggleAll($event)" />
                            <label for="preworkStudentSelectAll" class="d-block fs-16px">
                                <b>Select All</b>
                            </label>
                        </div>
                    </template>
                </template>
                <template #cdata="props">
                    <td v-if="test.settings.allowPdfDownload" class="selectAllFixedWidth">
                        <div class="newCheckboxField smaller-ver flex justifyCenter align-items">
                            <input
                                :id="'file-name-' + props.model.uuid"
                                type="checkbox"
                                :checked="isMaterialSelected(props.model)"
                                :value="props.model.uuid"
                                @click="toggleMaterial(props.model)"
                            />
                        </div>
                    </td>
                    <td>
                        <label :for="'file-name-' + props.model.uuid" class="fw-normal">
                            <span class="visible-hidden">Select </span>
                            {{ props.model.material.filename }}
                        </label>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <p>
                            {{ (props.model.material.size / 1000000).toFixed(2) }}
                            MB
                        </p>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <p>
                            <template v-if="props.model.isDownloaded">
                                <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Downloaded
                            </template>
                            <template v-else-if="props.model.isOpen">
                                <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true"></i>Opened
                            </template>
                            <template v-else> <i class="fa fa-circle fullAccessCircle marginRight8" aria-hidden="true"></i>Not Downloaded </template>
                        </p>
                    </td>
                    <td>
                        <div class="buttonGroupInline">
                            <button
                                v-if="test.settings.allowPdfDownload"
                                class="btn btn-primary"
                                aria-label="Download"
                                @click.prevent="download([props.model])"
                            >
                                <i class="fa fa-download marginRight8" aria-hidden="true"></i><span class="hidden-xs hidden-sm">Download</span>
                            </button>
                            <button
                                v-if="isPdf(props.model.material)"
                                class="btn btn-default"
                                aria-label="Preview"
                                @click.prevent="
                                    setMaterialStatus([props.model]);
                                    openViewPdfModal(props.model.material);
                                "
                            >
                                <i class="fas fa-eye marginRight8" aria-hidden="true"></i><span class="hidden-xs hidden-sm">Preview</span>
                            </button>
                            <a
                                v-else
                                class="btn btn-default"
                                target="_blank"
                                :href="props.model.material.url"
                                aria-label="Preview"
                                @click="setMaterialStatus([props.model])"
                            >
                                <i class="fas fa-eye marginRight8" aria-hidden="true"></i><span class="hidden-xs hidden-sm">Preview</span>
                            </a>
                        </div>
                    </td>
                </template>
            </kr-search>
            <div v-if="test.settings.allowPdfDownload" class="col-xs-12 col-md-4 buttonGroupInline marginTop20">
                <button
                    class="btn btn-primary"
                    :disabled="selected_materials.length == 0 ? true : undefined"
                    :aria-label="'Download Selected (' + selected_materials.length + ')'"
                    @click="download(selected_materials)"
                >
                    <i class="fa fa-download marginRight8" aria-hidden="true"></i>Download Selected ({{ selected_materials.length }})
                </button>
                <button
                    class="btn btn-default"
                    :disabled="selected_materials.length == 0 ? true : undefined"
                    aria-label="Cancel"
                    @click.prevent="
                        selected_materials = [];
                        selected_materials.splice();
                    "
                >
                    Cancel
                </button>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            component_done_loading: false,
            test: null,
            columns: {},
            materialsData: {
                meta: {},
                query: {},
                data: [],
            },
            selected_materials: [],
            attachment: null,
        };
    },
    created() {
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
        var that = this;

        if (this.$route.name != 'teachers.tests.preview.prework') {
            this.fetchTest();
        } else {
            this.fetchActivity().then(function (response) {
                that.test = response.data.data;
                that.test._isPreview = true;
                that.test._isTeacherPreview = true;
                that.test.settings = that.test.settings.prework;
                if (that.test.settings.allowPdfDownload) {
                    that.columns = {
                        checkbox: 'display:Checkbox|sortable:false|minWidth:154px|slotted:true',
                        file: 'display:File Name|sortable:false|width:50%',
                        size: 'display:File Size|sortable:false|width:10%|mobile:hide',
                        status: 'display:Status|sortable:false|width:15%|mobile:hide',
                    };
                } else {
                    that.columns = {
                        file: 'display:File Name|sortable:false|width:50%',
                        size: 'display:File Size|sortable:false|width:10%|mobile:hide',
                        status: 'display:Status|sortable:false|width:20%|mobile:hide',
                    };
                }
                that.component_done_loading = true;
            });
        }
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.testUuid) {
                    return false;
                }
                if (that.test.settings.type == 'synchronous') {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime = e.test.settings.endTime;
                } else {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime =
                        moment
                            .utc(that.test.myProgression.endTime, 'YYYY-MM-DDThh:mm:ssZ')
                            .add(e.test.settings.diffInSeconds, 'seconds')
                            .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    //asynchronous case here
                }
                let old_status = that.test.status;
                that.test.status = e.test.status;

                if (that.test.status == 'completed') {
                    //submited the test
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: "Time's up!!",
                    });
                    that.$router.push({ name: 'tests.index' });
                }
                if (that.test.status == 'ended') {
                    //submited the test
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has ended the test',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
                if (['ended', 'completed'].includes(old_status) && that.test.status == 'ongoing') {
                    Events.fire('trigger-test-restart');
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has unpublished this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c2 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityUnpublished', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'ActivityUnpublished',
                handler: h2,
            });

            let h3 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    Events.fire('materials_listing_refresh');
                }
            };
            let c3 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestMaterialsUpdated', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'TestMaterialsUpdated',
                handler: h3,
            });

            let h4 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has deleted this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c4 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityDeleted', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'ActivityDeleted',
                handler: h4,
            });
        },
        download(materials = []) {
            var that = this;
            let file_handles = '';
            for (var i = 0; i < materials.length; i++) {
                let output = materials[i].material.url.split(/[\/ ]+/).pop();
                file_handles += output;
                if (i < materials.length - 1) {
                    file_handles += ',';
                }
            }
            let instance = axios.create({
                baseURL: '',
                transformRequest: [
                    (data, headers) => {
                        delete headers.common.Authorization;
                        return data;
                    },
                ],
            });
            if (materials.length > 1) {
                file_handles = '[' + file_handles + ']';
                instance({
                    url: 'https://process.filestackapi.com/zip/' + file_handles,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'download.zip');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    that.setMaterialStatus(materials, 'downloaded');
                });
            } else {
                instance({
                    url: materials[0].material.url,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', materials[0].material.filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    that.setMaterialStatus(materials, 'downloaded');
                });
            }
        },
        isMaterialSelected(material) {
            let idx = _.findIndex(this.selected_materials, function (o) {
                return o.uuid == material.uuid;
            });
            if (idx != -1) {
                return true;
            }
            return false;
        },
        setMaterialStatus(materials = [], status = 'opened') {
            if (this.test._isPreview) {
                return;
            }
            let data = [];
            var that = this;
            for (var i = 0; i < materials.length; i++) {
                let datum = { uuid: materials[i].uuid };
                if (status == 'opened') {
                    datum.opened = true;
                }
                if (status == 'downloaded') {
                    datum.downloaded = true;
                }
                data.push(datum);
            }
            axios
                .put('/student/tests/' + this.test.uuid + '/materials', {
                    data: data,
                })
                .then(function (response) {
                    Events.fire('materials_listing_refresh');
                });
        },
        toggleMaterial(material) {
            let idx = _.findIndex(this.selected_materials, function (o) {
                return o.uuid == material.uuid;
            });
            if (idx != -1) {
                this.selected_materials.splice(idx, 1);
            } else {
                this.selected_materials.push(material);
            }
        },
        toggleAll(event) {
            var that = this;
            if (this.isAllToggled) {
                for (var i = this.materialsData.data.length - 1; i >= 0; i--) {
                    let idx = _.findIndex(this.selected_materials, function (o) {
                        return o.uuid == that.materialsData.data[i].uuid;
                    });
                    if (idx != -1) {
                        this.selected_materials.splice(idx, 1);
                    }
                }
            } else {
                //this.selected_materials_uuid = [];
                for (var i = 0; i < this.materialsData.data.length; i++) {
                    let idx = _.findIndex(this.selected_materials, function (o) {
                        return o.uuid == that.materialsData.data[i].uuid;
                    });
                    if (idx == -1) {
                        this.selected_materials.push(this.materialsData.data[i]);
                    }
                }
            }
        },
        getMaterialsData(data) {
            this.materialsData = data;
            this.materialsData.data = this.materialsData.data.data.data;
        },
        fetchTest() {
            var that = this;
            axios
                .get('student/tests/' + this.$route.params.id)
                .then(function (response) {
                    that.test = response.data.data;
                    //that.test.settings.allowPdfDownload = false;

                    if (that.test.settings.allowPdfDownload) {
                        that.columns = {
                            checkbox: 'display:Checkbox|sortable:false|minWidth:154px|slotted:true',
                            file: 'display:File Name|sortable:false|width:50%',
                            size: 'display:File Size|sortable:false|width:10%|mobile:hide',
                            status: 'display:Status|sortable:false|width:15%|mobile:hide',
                        };
                    } else {
                        that.columns = {
                            file: 'display:File Name|sortable:false|width:50%',
                            size: 'display:File Size|sortable:false|width:10%|mobile:hide',
                            status: 'display:Status|sortable:false|width:20%|mobile:hide',
                        };
                    }
                    that.component_done_loading = true;
                    that.processEchoListener();
                })
                .catch(function (errors) {});
        },
        fetchActivity() {
            return axios.get('activities/' + this.$route.params.id);
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.test.settings.allowPdfDownload,
            });
        },
    },
    components: {
        'test-topbar': require(`./../partials/topbar.vue`).default,
    },
    computed: {
        isAllToggled() {
            var that = this;
            if (this.materialsData.data.length == 0) {
                return false;
            }
            for (var i = 0; i < this.materialsData.data.length; i++) {
                let idx = _.findIndex(this.selected_materials, function (o) {
                    return o.uuid == that.materialsData.data[i].uuid;
                });
                if (idx == -1) {
                    return false;
                }
            }
            return true;
        },
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        //from mixin.
        Events.off('current-question-changed');
    },
};
</script>
<style scoped>
.attachmentLink {
    color: #222;
}

.far {
    color: #717171;
}

.attachmentFile {
    margin-top: 20px;
}

.attachmentFile:first-child {
    margin-top: 0;
}
</style>
