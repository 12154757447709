<template>
    <div>
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <h2>Item Analysis ({{ items.length }})</h2>
            </template>
            <template #content>
                <div v-if="component_done_loading">
                    <div class="hidden-xs hidden-sm table-responsive new-table-container">
                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-center-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                        </div>

                        <div class="scrollable-table-wrapper flex" tabindex="0">
                            <div class="new-left-table">
                                <caption class="visible-hidden">
                                    Item analysis table ({{
                                        items.length
                                    }}
                                    questions)
                                </caption>
                                <table class="table">
                                    <thead>
                                        <tr class="sticky">
                                            <th>
                                                Question
                                                <template v-if="sort === 'question_no'">
                                                    <a v-if="order === 'asc'" @click.prevent="toggle('question_no')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else @click.prevent="toggle('question_no')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else @click.prevent="toggle('question_no')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                Fail Rate
                                                <template v-if="sort === 'failRate'">
                                                    <a v-if="order === 'asc'" @click.prevent="toggle('failRate')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else @click.prevent="toggle('failRate')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else @click.prevent="toggle('failRate')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, idx) in items" :key="item.id">
                                            <td>
                                                <button
                                                    class="btn gradient btn-default"
                                                    :aria-label="'row ' + idx + ' Question number ' + item.question_no + ' click to view the question'"
                                                    tabindex="0"
                                                    @click.prevent="openQuestionInfoModal(questions[item.question_no - 1])"
                                                >
                                                    {{ item.question_no }}.<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                                </button>
                                            </td>
                                            <td :aria-label="'row ' + idx + ' Fail Rate ' + round(items[idx].failRate) + '%'" tabindex="0">
                                                {{ round(items[idx].failRate) }}%
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <scroll-box-shadow class="new-center-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th v-for="(value, index) in max_keys" :key="index">
                                                {{ String.fromCharCode(65 + index) }}
                                                <template v-if="sort === 'options.' + index + '.percent'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('options.' + index + '.percent')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('options.' + index + '.percent')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('options.' + index + '.percent')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(question, idx) in items" :key="question.id">
                                            <template v-for="(option, option_idx) in question.options" :key="question.id + '-' + '-option-' + option_idx">
                                                <td
                                                    :aria-label="
                                                        option.isCorrect
                                                            ? 'row ' + idx + ' option ' + option.key + ' ' + `${round(option.percent)}` + '% is correct answer'
                                                            : 'row ' + idx + ' option ' + option.key + ' ' + `${round(option.percent)}` + '%'
                                                    "
                                                    tabindex="0"
                                                >
                                                    <div
                                                        :class="{
                                                            minWidth82px: !option.isCorrect && !optionCorrect(question.question, option.key),
                                                            'statusTag  whiteSpaceNowrap paddingBottom3':
                                                                option.isCorrect || optionCorrect(question.question, option.key),
                                                            tagCorrect: option.isCorrect,
                                                            tagNewCorrect: optionCorrect(question.question, option.key),
                                                        }"
                                                    >
                                                        <span class="paddingTop2">{{ round(option.percent) }}%</span>
                                                        <i
                                                            v-if="option.isCorrect || optionCorrect(question.question, option.key)"
                                                            class="fas fa-check-circle marginLeft8"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                </td>
                                            </template>
                                            <td
                                                v-for="(n, index) in max_keys - question.options.length"
                                                :key="n + '-' + index"
                                                :aria-label="'row ' + idx + ' option ' + String.fromCharCode(n) + ' ' + ' this is empty'"
                                                tabindex="0"
                                            ></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </scroll-box-shadow>

                            <div class="new-right-table">
                                <table class="table">
                                    <thead>
                                        <tr class="sticky">
                                            <th class="minWidth100px maxWidth100px width100px">
                                                Points
                                                <template v-if="sort === 'score'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('score')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('score')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('score')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th class="minWidth130px maxWidth130px width130px">
                                                Avg. Time
                                                <template v-if="sort === 'nonFormattedAverageCompletionTimePerQuestion'">
                                                    <a
                                                        v-if="order === 'asc'"
                                                        aria-label="asc"
                                                        @click.prevent="toggle('nonFormattedAverageCompletionTimePerQuestion')"
                                                    >
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('nonFormattedAverageCompletionTimePerQuestion')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('nonFormattedAverageCompletionTimePerQuestion')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th class="minWidth120px maxWidth120px width120px">
                                                <div class="inlineFlex alignFlexEnd">
                                                    <label class="word-wrap-break-word fontSize16">
                                                        Learners
                                                        <br />
                                                        Finished
                                                    </label>
                                                    <template v-if="sort === 'percentFinishedLearners'">
                                                        <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('percentFinishedLearners')">
                                                            <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                        </a>
                                                        <a v-else aria-label="desc" @click.prevent="toggle('percentFinishedLearners')">
                                                            <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                        </a>
                                                    </template>
                                                    <a v-else aria-label="sort" @click.prevent="toggle('percentFinishedLearners')">
                                                        <i class="fa fa-sort" aria-hidden="true" />
                                                    </a>
                                                </div>
                                            </th>
                                            <th class="minWidth125px maxWidth125px width125px">
                                                % Correct
                                                <template v-if="sort === 'percentCorrect'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('percentCorrect')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('percentCorrect')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('percentCorrect')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th class="minWidth95px maxWidth95px width95px">
                                                PBCC
                                                <template v-if="sort === 'pbcc'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('pbcc')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('pbcc')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('pbcc')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(question, idx) in items" :key="idx">
                                            <td :aria-label="'row ' + idx + ' ' + question.question.score + 'points'" tabindex="0">
                                                {{ question.question.score }}
                                            </td>
                                            <td :aria-label="'row ' + idx + ' Average Time ' + question.averageCompletionTimePerQuestion" tabindex="0">
                                                {{ question.averageCompletionTimePerQuestion }}
                                            </td>
                                            <td
                                                :aria-label="'row ' + idx + ' Leaner finished ' + `${round(question.percentFinishedLearners)}` + '%'"
                                                tabindex="0"
                                            >
                                                {{ round(question.percentFinishedLearners) }}%
                                            </td>
                                            <td :aria-label="'row ' + idx + ' ' + `${round(question.percentCorrect)}` + '% correct'" tabindex="0">
                                                {{ round(question.percentCorrect) }}%
                                            </td>
                                            <td :aria-label="question.pbcc ? `row ${idx} ${question.pbcc.toFixed(2)}% PBCC ` : '-'" tabindex="0">
                                                <template v-if="question.pbcc"> {{ question.pbcc.toFixed(2) }} </template><template v-else> - </template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-center-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar">
                                <div style="height: 0.1px" />
                            </div>
                        </div>
                    </div>

                    <div class="hidden-md hidden-lg table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        IRAT Fail Rate
                                        <template v-if="sort === 'failRate'">
                                            <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('failRate')">
                                                <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                            </a>
                                            <a v-else aria-label="desc" @click.prevent="toggle('failRate')">
                                                <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                            </a>
                                        </template>
                                        <a v-else aria-label="sort" @click.prevent="toggle('failRate')">
                                            <i class="fa fa-sort" aria-hidden="true" />
                                        </a>
                                    </th>
                                    <th>
                                        Question
                                        <template v-if="sort === 'question_no'">
                                            <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('question_no')">
                                                <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                            </a>
                                            <a v-else aria-label="desc" @click.prevent="toggle('question_no')">
                                                <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                            </a>
                                        </template>
                                        <a v-else aria-label="sort" @click.prevent="toggle('question_no')">
                                            <i class="fa fa-sort" aria-hidden="true" />
                                        </a>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, idx) in items" :key="idx">
                                    <td>{{ round(item.failRate) }}%</td>
                                    <td>
                                        <button
                                            class="btn gradient btn-default"
                                            :aria-label="'row ' + idx + ' Question number ' + item.question_no + ' click to view the question'"
                                            @click.prevent="openQuestionInfoModal(questions[item.question_no - 1])"
                                        >
                                            {{ item.question_no }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-primary"
                                            :aria-label="questions[item.question_no - 1] + ' Information'"
                                            @click.prevent="openMoreInfoModal(item)"
                                        >
                                            <i class="fas fa-angle-right" aria-hidden="true" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <vcl-table v-else />
            </template>
        </kr-panel>
        <div
            id="itemAnalysisQuestionInfoModal"
            class="modal"
            data-backdrop="false"
            tabindex="-1"
            role="dialog"
            aria-labelledby="itemAnalysisQuestionInfoModal-title"
        >
            <div class="modal-dialog" :class="{ 'widthAuto maxWidth1000px': ['mcqs', 'mcqm'].includes(previewQuestion.type) }">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="itemAnalysisQuestionInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Question
                        </h2>
                    </div>
                    <div class="modal-body">
                        <label class="control-label"> Question {{ getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) }} </label>

                        <question-display :test-obj="testObj" :question="previewQuestion" />

                        <div v-if="['mcqs', 'mcqm'].includes(previewQuestion.type)" class="mcqStatisticsBarChart marginTop20">
                            <div class="panel-header">
                                <div>Answer Choices</div>
                            </div>
                            <div>
                                <table class="barChart">
                                    <tbody class="body">
                                        <template v-for="(option, option_idx) in previewQuestion.optionKeys">
                                            <tr>
                                                <div class="percentage" :style="'bottom:' + (option.percent.toFixed(0) / 100) * 80 + '%'">
                                                    {{ round(option.percent) }}%
                                                </div>
                                                <td
                                                    class="bars"
                                                    :class="{
                                                        correct: option.isCorrect,
                                                        wrong: !option.isCorrect,
                                                    }"
                                                    :style="'height:' + (option.percent.toFixed(0) / 100) * 80 + '%;'"
                                                />
                                                <td class="record">
                                                    <div>Choice {{ option.key }}</div>
                                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                                        <template v-if="model.student">
                                                            <div
                                                                v-tooltip="model.student.fullname"
                                                                class="btn btnDiscussionTeams marginTop10 cursor-norm"
                                                                tabindex="0"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label>{{ model.student.fullname }}</label>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- <div v-if="previewQuestion.remarks" class="gradient panel marginTop10">
                            <div class="panel-heading padding10">
                                <label>Question Notes</label>
                            </div>
                            <div class="panel-body padding10">
                                <p class="whiteSpaceBreakSpacesText">
                                    {{ previewQuestion.remarks }}
                                </p>
                            </div>
                        </div> -->

                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
			      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
			      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
				        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
				        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
				        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				    </div>
				    <div style="margin-top:20px;" v-else>
				        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
				    </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div id="itemAnalysisMoreInfoModal" class="modal" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="itemAnalysisMoreInfoModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="itemAnalysisMoreInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Details
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="flex itemAnalysisTable">
                            <div class="col-xs-8 col-sm-6 col-md-4">
                                <table class="table">
                                    <tr v-for="(option, option_idx) in previewQuestion.options">
                                        <th>{{ option.key }}</th>
                                        <td
                                            :aria-label="
                                                option.isCorrect
                                                    ? 'row ' + option_idx + ' option ' + option.key + ' ' + `${round(option.percent)}` + '% is correct answer'
                                                    : 'row ' + option_idx + ' option ' + option.key + ' ' + `${round(option.percent)}` + '%'
                                            "
                                            tabindex="0"
                                        >
                                            <div
                                                :class="{
                                                    minWidth82px: !option.isCorrect && !optionCorrect(previewQuestion.question, option.key),
                                                    'statusTag  whiteSpaceNowrap paddingBottom3':
                                                        option.isCorrect || optionCorrect(previewQuestion.question, option.key),
                                                    tagCorrect: option.isCorrect,
                                                    tagNewCorrect: optionCorrect(previewQuestion.question, option.key),
                                                }"
                                            >
                                                <span class="paddingTop2">{{ round(option.percent) }}%</span>
                                                <i
                                                    v-if="option.isCorrect || optionCorrect(previewQuestion.question, option.key)"
                                                    class="fas fa-check-circle marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-xs-4 col-sm-4 col-md-4">
                                <div class="row">
                                    <div class="card">
                                        <div class="card-header">Points</div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{ previewQuestionDetails.score }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header">Avg. Time</div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{ previewQuestion.averageCompletionTimePerQuestion }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="card">
                                        <div class="card-header">Leaner Finished</div>
                                        <div class="card-body">
                                            <p class="card-text">{{ round(previewQuestion.percentFinishedLearners) }}%</p>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header">% Correct</div>
                                        <div class="card-body">
                                            <p class="card-text">{{ round(previewQuestion.percentCorrect) }}%</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="card">
                                        <div class="card-header">PBCC</div>
                                        <div class="card-body">
                                            <div class="card-text">
                                                <template v-if="previewQuestion.pbcc">
                                                    {{ previewQuestion.pbcc.toFixed(2) }}
                                                </template>
                                                <template v-else> - </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VclTable } from 'vue-content-loading';
import { testMixins } from '../../../../../../../../mixins/test';

export default {
    mixins: [testMixins],
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            questions: [],
            max_keys: 0,
            previewQuestion: {},
            previewQuestionDetails: {
                score: null,
            },
            order: 'desc',
            sort: 'failRate',
            itemAnalysis: null,
            items: [],
            debouncedUpdate: null,
            lastUpdateTimestamp: '',
        };
    },
    created() {
        var that = this;
        this.debouncedUpdate = _.debounce(
            function () {
                that.fetchTest().then(function (response) {
                    response.data.data.itemAnalysis.item.forEach(function (itm) {
                        itm.id = itm.question.id;
                    });
                    that.lastUpdateTimestamp = moment.utc(response.headers['date'], 'ddd, DD MMM YYYY HH:mm:ss [GMT]', true).format('YYYY-MM-DD HH:mm:ss');
                    if (that.itemAnalysis == null) {
                        that.testObj.itemAnalysis = response.data.data.itemAnalysis;
                        /*Vue.set(
                            that.testObj,
                            'itemAnalysis',
                            response.data.data.itemAnalysis
                        );*/
                        that.itemAnalysis = response.data.data.itemAnalysis;
                    } else {
                        that.itemAnalysis.item = that.mergeArrayOfObjectsWithKey(that.itemAnalysis.item, response.data.data.itemAnalysis.item, 'id');
                    }
                    that.init();
                    if (!that.component_done_loading) {
                        that.component_done_loading = true;
                        Vue.nextTick(function () {
                            $('#itemAnalysisQuestionInfoModal .modal-dialog').draggable({
                                handle: '.modal-header',
                            });
                        });
                    }
                });
            },
            1000,
            { maxWait: 3000 }
        );
        this.boundedFetch();
        this.processEchoListeners();
    },
    mounted() {
        var that = this;

        $(window).on('resize.alignTable', function () {
            that.alignTable();
        });

        this.$root.$on('fontResized', () => {
            that.alignTable();
        });
    },
    updated() {
        this.alignTable();
    },
    beforeUnmount() {
        $(window).off('resize.alignTable');
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.test.uuid != that.testObj.uuid) {
                    return false;
                }
                that.boundedFetch();
            };
            let c1 = window.Echo.private(`course.${this.testObj.course.uuid}.teacher`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c2 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('StudentAnswerSaved', h2);

            this.echoChannels.push({
                channel: c2,
                event: 'StudentAnswerSaved',
                handler: h2,
            });
        },
        boundedFetch() {
            var that = this;
            let bounds = { offset: 0, limit: 5 };
            let concurrentUrls = [];
            let total = _.flatten(this.testObj.questions).length;
            for (var i = 0; i < total; i += bounds.limit) {
                concurrentUrls.push(that.fetchTest({ offset: i, limit: bounds.limit }, false));
            }
            axios.all(concurrentUrls).then(
                axios.spread((...res) => {
                    res.map((d) => d.data);
                    for (var i = 0; i < res.length; i++) {
                        if (i == 0) {
                            that.lastUpdateTimestamp = moment
                                .utc(res[i].headers['date'], 'ddd, DD MMM YYYY HH:mm:ss [GMT]', true)
                                .format('YYYY-MM-DD HH:mm:ss');
                            that.itemAnalysis = res[i].data.data.itemAnalysis;
                        } else {
                            that.itemAnalysis.item = [...that.itemAnalysis.item, ...res[i].data.data.itemAnalysis.item];
                            /*Vue.set(that.itemAnalysis, 'item', [
                                ...that.itemAnalysis.item,
                                ...res[i].data.data.itemAnalysis.item,
                            ]);*/
                        }
                    }
                    that.testObj.itemAnalysis = that.itemAnalysis;
                    //Vue.set(that.testObj, 'itemAnalysis', that.itemAnalysis);
                    that.init();
                    that.component_done_loading = true;
                    Vue.nextTick(function () {
                        $('#itemAnalysisQuestionInfoModal .modal-dialog').draggable({
                            handle: '.modal-header',
                        });
                    });
                })
            );
        },
        fetchTest(bounds = false, timestamp = true) {
            return axios.get(
                '/tests/' +
                    this.$route.params.id +
                    '/dashboard/items' +
                    (this.lastUpdateTimestamp != '' && timestamp ? `?timestamp=${this.lastUpdateTimestamp}` : '') +
                    (bounds ? `?offset=${bounds.offset}&limit=${bounds.limit}` : ''),
                { _internal: { hasLoadingBar: false } }
            );
        },
        init() {
            var that = this;
            if (_.isEmpty(this.itemAnalysis)) {
                this.itemAnalysis = { item: [] };
            }
            if (_.isEmpty(this.questions)) {
                this.questions = _.flatten(this.testObj.questions);
            }
            for (var i = 0; i < this.itemAnalysis.item.length; i++) {
                let idx = _.findIndex(this.questions, function (o) {
                    return o.id == that.itemAnalysis.item[i].id;
                });
                if (idx != -1) {
                    this.itemAnalysis.item[i].question_no = idx + 1;
                }

                this.itemAnalysis.item[i].score = this.itemAnalysis.item[i].question.score;
                if (this.itemAnalysis.item[i].options.length > this.max_keys) {
                    this.max_keys = this.itemAnalysis.item[i].options.length;
                }
            }
            this.items = _.clone(this.itemAnalysis.item);
            /*for(var i  = 0; i <this.items.length;i++){
					this.items[i].question_no = i+1;
				}*/
            this.sortList();
            var that = this;
            Vue.nextTick(function () {
                that.alignTable();

                $('.new-left-table-scrollbar').on('scroll', function () {
                    $('.new-left-table').scrollLeft($(this).scrollLeft());
                });

                $('.new-left-table').on('scroll', function () {
                    $('.new-left-table-scrollbar').scrollLeft($(this).scrollLeft());
                });

                $('.new-center-table-scrollbar').on('scroll', function () {
                    $('.new-center-table div').scrollLeft($(this).scrollLeft());
                });

                $('.new-center-table div').on('scroll', function () {
                    $('.new-center-table-scrollbar').scrollLeft($(this).scrollLeft());
                });

                $('.new-right-table-scrollbar').on('scroll', function () {
                    $('.new-right-table').scrollLeft($(this).scrollLeft());
                });

                $('.new-right-table').on('scroll', function () {
                    $('.new-right-table-scrollbar').scrollLeft($(this).scrollLeft());
                });
            });
        },
        sortList() {
            this.items = _.orderBy(this.items, [this.sort], [this.order]);

            var that = this;
            Vue.nextTick(function () {
                that.alignTable();
            });
        },
        toggle(column = '') {
            if (this.sort == column) {
                if (this.order == 'asc') {
                    this.order = 'desc';
                } else {
                    this.order = 'asc';
                }
            } else {
                this.order = 'asc';
                this.sort = column;
            }
            this.sortList();
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#itemAnalysisQuestionInfoModal').modal('show');
        },
        openMoreInfoModal(question) {
            this.previewQuestion = question;
            this.previewQuestionDetails.score = this.previewQuestion.question.score;
            $('#itemAnalysisMoreInfoModal').modal('show');
        },
        alignTable() {
            // new table
            $('.new-left-table thead tr').each(function (index) {
                var theadLeftHeight = $(this).height();
                var theadCenter = $('.new-center-table thead tr:eq(' + index + ')');
                var theadRight = $('.new-right-table thead tr:eq(' + index + ')');

                if (!theadCenter.length) {
                    return false;
                } else if (!theadRight.length) {
                    return false;
                }

                var theadCenterHeight = theadCenter.height();
                var theadRightHeight = theadRight.height();

                if (theadLeftHeight > theadCenterHeight && theadLeftHeight > theadRightHeight) {
                    theadCenter.height(theadLeftHeight);
                    theadRight.height(theadLeftHeight);
                } else if (theadCenterHeight > theadCenterHeight && theadCenterHeight > theadRightHeight) {
                    $(this).height(theadCenterHeight);
                    theadRight.height(theadCenterHeight);
                } else {
                    $(this).height(theadRightHeight);
                    theadCenter.height(theadRightHeight);
                }
            });

            $('.new-left-table tbody tr').each(function (index) {
                var rowLeftHeight = $(this).height();
                var rowCenter = $('.new-center-table tbody tr:eq(' + index + ')');
                var rowRight = $('.new-right-table tbody tr:eq(' + index + ')');

                if (!rowCenter.length) {
                    return false;
                } else if (!rowRight.length) {
                    return false;
                }

                var rowCenterHeight = rowCenter.height();
                var rowRightHeight = rowRight.height();

                if (rowLeftHeight >= rowCenterHeight && rowLeftHeight >= rowRightHeight) {
                    rowCenter.height(rowLeftHeight);
                    rowRight.height(rowLeftHeight);
                } else if (rowCenterHeight >= rowLeftHeight && rowCenterHeight >= rowRightHeight) {
                    $(this).height(rowCenterHeight);
                    rowRight.height(rowCenterHeight);
                } else {
                    $(this).height(rowRightHeight);
                    rowCenter.height(rowRightHeight);
                }
            });

            $('.new-right-table').scroll(function () {
                $('.new-left-table').scrollTop($(this).scrollTop());
                $('.new-center-table div').scrollTop($(this).scrollTop());
            });

            $('.new-center-table div').scroll(function () {
                $('.new-left-table').scrollTop($(this).scrollTop());
                $('.new-right-table').scrollTop($(this).scrollTop());
            });

            $('.new-left-table').scroll(function () {
                $('.new-center-table div').scrollTop($(this).scrollTop());
                $('.new-right-table').scrollTop($(this).scrollTop());
            });

            var widthOfTheLeftTable = $('.new-table-container .new-left-table table').width();
            $('.new-table-container .new-left-table-scrollbar div').width(widthOfTheLeftTable + 'px');

            var widthOfTheCenterTable = $('.new-table-container .new-center-table table').width();
            $('.new-table-container .new-center-table-scrollbar div').width(widthOfTheCenterTable + 'px');

            var widthOfTheRightTable = $('.new-table-container .new-right-table table').width();
            $('.new-table-container .new-right-table-scrollbar div').width(widthOfTheRightTable + 'px');
        },
        optionCorrect(question, key) {
            if (['ended', 'completed'].includes(this.testObj.status) && question.type == 'mcqs') {
                for (var i = 0; i < this.testObj.acceptedNewAnswers.length; i++) {
                    if (this.testObj.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        for (var j = 0; j < this.testObj.acceptedNewAnswers[i].acceptedAnswers.length; j++) {
                            if (this.testObj.acceptedNewAnswers[i].acceptedAnswers[j] == key) {
                                return true;
                            }
                        }
                    }
                }
            }
        },
    },
    components: {
        'question-display': require(`./../../../../../../questions/partials/question-display.vue`).default,
        VclTable,
    },
};
</script>

<style lang="scss" scoped>
.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tablewDiv .table650px .table {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: -2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tableDiv1 {
    width: 235px;
}

.table650px {
    width: 650px;
}

.tablewDiv .tableDiv {
    width: calc(100% - 885px);
    min-width: 200px;
}

.tableDiv1 .table > thead > tr > th,
.tableDiv1 .table > thead > tr > td,
.tableDiv1 .table > tbody > tr > th,
.tableDiv1 .table > tbody > tr > td,
.tableDiv1 .table > tfoot > tr > th,
.tableDiv1 .table > tfoot > tr > th,
.table650px .table > thead > tr > th,
.table650px .table > thead > tr > td,
.table650px .table > tbody > tr > th,
.table650px .table > tbody > tr > td,
.table650px .table > tfoot > tr > th,
.table650px .table > tfoot > tr > th,
.tableDiv .table > thead > tr > th,
.tableDiv .table > thead > tr > td,
.tableDiv .table > tbody > tr > th,
.tableDiv .table > tbody > tr > td,
.tableDiv .table > tfoot > tr > th,
.tableDiv .table > tfoot > tr > th {
    padding: 15px;
}
thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}
table {
    border-top: 0;
}
#header-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}
#tableDivOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.tablewDiv .tableDiv1 .table {
    border-left: 0;
    box-shadow: none;
    border-bottom: 0;
}

.tablewDiv .tableDiv .table {
    box-shadow: none;
    border-bottom: 0;
}

.tablewDiv .table650px .table {
    box-shadow: none;
    border-bottom: 0;
}

#itemAnalysisIRAT {
    border: 1px solid #ddd;
    border-radius: 3px;
}
/*
.mobile-table-overflowHidden {
	overflow:hidden;
	overflow-x:auto;
}*/

/* new table */

.new-table-container {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 0;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .new-left-table {
        min-width: 240px;
        width: 240px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-center-table {
        min-width: calc(100% - 240px - 570px);
        width: calc(100% - 240px - 570px);
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-right-table {
        width: 570px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 5px;
            background: #d8d8d8;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #989b9c;
            border-radius: 2px;
        }
    }

    .new-left-table-scrollbar {
        width: 240px;
    }

    .new-center-table-scrollbar {
        width: calc(100% - 240px - 570px);
    }

    .new-right-table-scrollbar {
        width: 570px;
    }

    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar,
            .new-center-table-scrollbar,
            .new-right-table-scrollbar {
                border: 0;
            }
        }

        & > div {
            border: 1px solid #ddd;
            overflow: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}
</style>
