<template>
    <div v-if="!form.isHidden(name)" :style="style" :class="[cls, form.errors.has(name) ? 'has-error' : '']">
        <template v-if="hasLabel">
            <label class="control-label" :for="name" v-dompurify-html="display" />
        </template>
        <div>
            <div class="input-group date">
                <input
                    :id="name"
                    v-model="dpLabel"
                    :name="name"
                    type="text"
                    :disabled="form.isDisabled(name) ? true : undefined"
                    :placeholder="placeholder"
                    class="form-control"
                    @keydown=""
                />
                <div class="input-group-addon" @click="toggleDatepicker()">
                    <i class="fa fa-calendar" :aria-label="placeholder" />
                </div>
            </div>
            <span v-show="form.errors.has(name)" class="help-block">
                <strong>{{ form.errors.get(name) }}</strong>
            </span>
        </div>
    </div>
</template>
<script>
import daterangepicker from 'daterangepicker';
export default {
    props: ['display', 'form', 'name', 'options'],
    data: function () {
        return {
            time: false,
            hasLabel: true,
            placeholder: null,
            dpLabel: '',
            style: '',
            cls: 'form-group',
            minDate: null,
            maxDate: null,
            allowedDates: null,
        };
    },
    watch: {
        'form.model': {
            deep: true,
            handler() {
                if (!_.get(this.form.model, this.name)) {
                    this.dpLabel = '';
                }
            },
        },
        options: {
            deep: true,
            handler() {
                var that = this;
                Vue.nextTick(function () {
                    that.dpLabel = '';
                    that.initMajorVariables();
                    that.initDatePicker();
                });
            },
        },
    },
    created() {
        //placeholder
        var placeholder = _.get(this, 'options.placeholder', false);
        if (placeholder) {
            this.placeholder = placeholder;
        }
        //placeholder
        var label = _.get(this, 'options.hasLabel', null);
        if (label != null) {
            this.hasLabel = label;
        }
        //placeholder
        var cls = _.get(this, 'options.cls', null);
        if (cls != null) {
            this.cls = cls;
        }
        var style = _.get(this, 'options.style', null);
        if (style != null) {
            this.style = label;
        }
        if (_.get(this, 'options.time', false)) {
            this.time = true;
        }
        this.initMajorVariables();
    },
    mounted() {
        this.initDatePicker();
    },
    beforeUnmount() {
        if ($('input[name="' + this.name + '"]').data('daterangepicker')) {
            $('input[name="' + this.name + '"]')
                .data('daterangepicker')
                .remove();
        }
    },
    methods: {
        toggleDatepicker() {
            $('input[id="' + this.name + '"]')
                .data('daterangepicker')
                .toggle();
        },
        initMajorVariables() {
            var minDate = _.get(this, 'options.minDate', null);
            if (minDate != null) {
                if (this.time) {
                    this.minDate = this.convertToReadableDate(minDate, 'YYYY-MM-DD HH:mm:ss').date;
                } else {
                    this.minDate = this.convertToReadableDate(minDate, 'YYYY-MM-DD').date;
                }
            } else {
                if (this.time == false) {
                    this.minDate = '1930-01-01';
                } else {
                    this.minDate = '1930-01-01 00:00:00';
                }
            }
            //placeholder
            var maxDate = _.get(this, 'options.maxDate', null);
            if (maxDate != null) {
                if (this.time) {
                    this.maxDate = this.convertToReadableDate(maxDate, 'YYYY-MM-DD HH:mm:ss').date;
                } else {
                    this.maxDate = this.convertToReadableDate(maxDate, 'YYYY-MM-DD').date;
                }
            }
            //placeholder
            var allowedDates = _.get(this, 'options.allowedDates', null);
            if (allowedDates != null) {
                this.allowedDates = allowedDates;
            }
        },
        initDatePicker() {
            var that = this;
            var parentEl = _.get(this, 'options.parentEl', 'body');
            var change = function (start) {
                this.dpLabel = start.format('YYYY-MM-DD');
                this.form.errors.clear(this.name);
                this.$emit('change', start.format('YYYY-MM-DD'));
                $('#' + this.name).val(start.format('YYYY-MM-DD'));
                //_.set(this.form.model,this.name,start.utc().format('YYYY-MM-DD')+'T00:00:00Z');
                _.set(this.form.model, this.name, start.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z');
            }.bind(this);
            var changeWithTime = function (start) {
                this.dpLabel = start.format('YYYY-MM-DD HH:mm:ss');
                this.form.errors.clear(this.name);
                this.$emit('change', start.format('YYYY-MM-DD HH:mm:ss'));
                $('#' + this.name).val(start.format('YYYY-MM-DD HH:mm:ss'));
                _.set(this.form.model, this.name, start.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z');
            }.bind(this);

            if (!this.dpLabel && _.get(this.form.model, this.name)) {
                var start = _.get(this.form.model, this.name);
                if (this.time == false) {
                    start = that.convertToReadableDate(start, 'YYYY-MM-DD').date;
                    this.dpLabel = start;
                    $('input[id="' + this.name + '"]').daterangepicker({
                        parentEl: parentEl,
                        minDate: that.minDate,
                        maxDate: that.maxDate,
                        startDate: start,
                        singleDatePicker: true,
                        showDropdowns: true,
                        autoUpdateInput: false,
                        locale: {
                            format: 'YYYY-MM-DD',
                        },
                        isInvalidDate: function (date) {
                            var formatted = date.format('YYYY-MM-DD');
                            if (that.allowedDates != null) {
                                return that.allowedDates.indexOf(formatted) == -1;
                            } else {
                                return false;
                            }
                        },
                    });
                    $('input[id="' + this.name + '"]').on('apply.daterangepicker', function (ev, picker) {
                        change(picker.startDate);
                    });
                    $('input[id="' + this.name + '"]').on('cancel.daterangepicker', function (ev, picker) {
                        $(this).val('');
                        _.set(that.form.model, that.name, '');
                    });
                } else {
                    start = that.convertToReadableDate(start, 'YYYY-MM-DD HH:mm:ss').date;
                    this.dpLabel = start;
                    $('input[id="' + this.name + '"]').daterangepicker({
                        parentEl: parentEl,
                        minDate: that.minDate,
                        maxDate: that.maxDate,
                        startDate: start,
                        singleDatePicker: true,
                        showDropdowns: true,
                        timePicker: true,
                        timePickerIncrement: 1,
                        autoUpdateInput: false,
                        timePickerIncrement: 5,
                        locale: {
                            format: 'YYYY-MM-DD HH:mm:ss',
                        },
                        isInvalidDate: function (date) {
                            var formatted = date.format('YYYY-MM-DD');
                            if (that.allowedDates != null) {
                                return that.allowedDates.indexOf(formatted) == -1;
                            } else {
                                return false;
                            }
                        },
                    });
                    $('input[id="' + this.name + '"]').on('apply.daterangepicker', function (ev, picker) {
                        changeWithTime(picker.startDate);
                    });
                    $('input[id="' + this.name + '"]').on('cancel.daterangepicker', function (ev, picker) {
                        $(this).val('');
                        _.set(that.form.model, that.name, '');
                    });
                }
            } else {
                if (this.time == false) {
                    var a = {
                        parentEl: parentEl,
                        singleDatePicker: true,
                        autoUpdateInput: false,
                        showDropdowns: true,
                        locale: {
                            format: 'YYYY-MM-DD',
                        },
                        isInvalidDate: function (date) {
                            var formatted = date.format('YYYY-MM-DD');

                            if (that.allowedDates != null) {
                                return that.allowedDates.indexOf(formatted) == -1;
                            } else {
                                return false;
                            }
                        },
                    };
                    if (that.minDate != null) {
                        a.minDate = that.minDate;
                    }
                    if (that.maxDate != null) {
                        a.maxDate = that.maxDate;
                    }
                    $('input[id="' + this.name + '"]').daterangepicker(a);
                    $('input[id="' + this.name + '"]').on('apply.daterangepicker', function (ev, picker) {
                        change(picker.startDate);
                    });
                    $('input[id="' + this.name + '"]').on('cancel.daterangepicker', function (ev, picker) {
                        $(this).val('');
                        _.set(that.form.model, that.name, '');
                    });
                } else {
                    var a = {
                        parentEl: parentEl,
                        singleDatePicker: true,
                        showDropdowns: true,
                        timePicker: true,
                        timePickerIncrement: 5,
                        autoUpdateInput: false,
                        locale: {
                            format: 'YYYY-MM-DD HH:mm:ss',
                        },
                        isInvalidDate: function (date) {
                            var formatted = date.format('YYYY-MM-DD');
                            if (that.allowedDates != null) {
                                return that.allowedDates.indexOf(formatted) == -1;
                            } else {
                                return false;
                            }
                        },
                    };
                    if (that.minDate != null) {
                        a.minDate = that.minDate;
                    }
                    if (that.maxDate != null) {
                        a.maxDate = that.maxDate;
                    }
                    $('input[id="' + this.name + '"]').daterangepicker(a);
                    $('input[id="' + this.name + '"]').on('apply.daterangepicker', function (ev, picker) {
                        changeWithTime(picker.startDate);
                    });
                    $('input[id="' + this.name + '"]').on('cancel.daterangepicker', function (ev, picker) {
                        $(this).val('');
                        _.set(that.form.model, that.name, '');
                    });
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.input-group {
    &.date {
        &:focus-within {
            outline: 4px solid #1941a6;
            outline-offset: 3px;
            box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        }

        .form-control:focus-visible {
            outline: 0;
            outline-offset: 0;
            box-shadow: none;
        }
    }
}
</style>
